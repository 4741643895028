@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

.custom-snackbar {
  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    border-radius: 8px !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action {
    color: white !important;
  }
}

.snackbar-success {
  .mdc-snackbar__surface {
    background-color: green !important;
  }

  .mdc-snackbar__label {
    color: white !important;
  }
}

.snackbar-error {
  .mdc-snackbar__surface {
    background-color: red !important;
  }

  .mdc-snackbar__label {
    color: white !important;
  }
}

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
html {
  // --vex-color-primary: blue;
  // --vex-color-primary-contrast: white;

  // --vex-color-accent: yellow;
  // --vex-color-accent-contrast: black;

  // --vex-color-warn: yellow;
  // --vex-color-warn-contrast: black;
}
